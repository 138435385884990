import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import {
	Button,
	Icon,
	Text,
	Badge,
	VerticalList,
	HorizontalList,
	CommonLines,
	LearningPathPopup,
	StarRatingWithLogo,
} from "@website-builder/ui/shared/elements";
import {
	HeroBannerWRWrapper,
	Flow,
	HeroFormWrapper,
	COLORS,
	ImageSectionWrapper,
	ValueIconBarWrapper,
	FooterWrapper,
	HeroBannerVideoPlayer,
	VideoWrapper,
} from "./styles";
import {
	CloudinaryImage,
	Form,
	Select,
	Input,
	Separator,
	Image,
	ProgramInfo,
	HubspotStagedForm,
	VideoPlayer,
	Modal,
	VideoElement,
} from "@website-builder/ui/shared/elements";
import FormWrapperTransform from "../FormWrapper/transform";
import FormWrapper from "../FormWrapper";
import { BUTTON_INTERACTION } from "@website-builder/utilities/constants/click-events.js";
import { GetReferrer } from "@website-builder/utilities/utils/GetReferrer.js";
import { validateEmail } from "@website-builder/utilities/utils/validateEmail.js";
import { isBusinessVerticalB2U } from "@website-builder/utilities/utils/helpers.js";
import {
	formatColor,
	handleCTAClick,
} from "@website-builder/utilities/utils/DesignUtils.js";
import { callB2CIdentifyEvent } from "@website-builder/utilities/utils/utils.js";
import {
	TABLET_M_BP,
	MOBILE_L_BP,
} from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
import AlumniBar from "../AlumniBar";
import MultiStepForm from "./MultiStepForm";
const isB2U = isBusinessVerticalB2U();
const HeroBannerWR = (props) => {
	const {
		icon,
		label,
		heading,
		subHeading,
		text,
		h1Content = "heading",
		bottomBorderColor,
		brandBar,
		listPointers,
		form,
		multiStepForm,
		alumniBar,
		cta,
		learningPathPopup,
		imageSection,
		rightSection,
		hasDarkBackground,
		lineGraphicVariant,
		hasBackgroundImage,
		pageSection = "",
		footer,
		context,
		programInfoProps,
		hideProgramInfoInMobile,
		removeAdditionalTextSpace,
		fetchApiUrl,
		backgroundVideo = null,
		darkenVideo = false,
		isContentCentered = false,
		starRatingWithLogo,
	} = props;

	const BUSINESS_VERTICAL = process.env.GATSBY_BUSINESS_VERTICAL;
	const isUniversityHeroBanner = BUSINESS_VERTICAL === "b2u";
	const [email, setEmail] = useState("");
	const [program, setProgram] = useState(null); //used to set the program typeform link for the selected prgram
	const [error, setError] = useState({ email: "", program: "" });
	const [showErrors, setShowErrors] = useState(false);
	const [isCenter, setIsCenter] = useState(
		imageSection ||
			rightSection ||
			programInfoProps?.shortName ||
			!isContentCentered
			? false
			: true,
	);
	const isMobile = useMediaQuery(`(max-width: ${TABLET_M_BP}px)`);
	const isMobileL_BP = useMediaQuery(`(max-width: ${MOBILE_L_BP}px)`);
	const [formMessage, setFormMessage] = useState({ status: "", message: "" });
	const [loading, setLoading] = useState(false);

	const [openVideoModal, setOpenVideoModal] = useState(false);

	const headingColor = !hasDarkBackground
		? COLORS.HEADING_DARK
		: COLORS.HEADING_LIGHT;
	const textColor = !hasDarkBackground ? COLORS.TEXT_DARK : COLORS.TEXT_LIGHT;
	const multiStepHasContentAbove = heading || subHeading || text ? true : false;
	const multiStepHasContentBelow =
		brandBar || listPointers || alumniBar || cta ? true : false;

	useEffect(() => {
		if (imageSection || rightSection) {
			setIsCenter(false);
		}
	}, [imageSection, rightSection]);

	let listPointerItems = listPointers?.text.map((item, i) => {
		return (
			<Text
				variant="paragraph_M"
				tag="div"
				color={textColor}
				key={`list-item-${i}`}
			>
				{item?.text}
			</Text>
		);
	});

	const handleButtonClick = (cta) => {
		let eventData = {
			page_section: pageSection,
			click_text: cta?.buttonText,
			redirect_to: cta?.link?.link,
		};
		handleCTAClick(cta, eventData);
	};

	const callbackOnError = () => {
		setLoading(false);
		setFormMessage({
			status: "error",
			message: "We encountered an error. Please try again.",
		});
	};

	const handleProgramFormSubmit = (hasProgram, cta = { buttonText: "" }) => {
		setFormMessage({ status: "", message: "" });
		let hasErrorOccured = false;
		setShowErrors(true);
		let doEmailValidation = true;
		if (hasProgram) {
			if (!program?.workshop_id) {
				setError({ ...error, program: "Please select a program" });
				hasErrorOccured = true;
				doEmailValidation = false;
			} else {
				setError({ ...error, program: "" });
			}
		} else {
			doEmailValidation = true;
		}
		if (doEmailValidation) {
			const emailValidation = validateEmail(email);
			if (emailValidation === true) {
				setError({ ...error, email: "" });
				hasErrorOccured = false;
			} else {
				setError({ ...error, email: emailValidation });
				hasErrorOccured = true;
			}
		}

		if (hasErrorOccured) {
			return;
		}

		if (hasProgram) {
			context["typeformLink"] = program?.link;
			context["dripTagPrefix"] = program?.dripTagPrefix;
			context["workshop_id"] = program?.workshop_id;
		}
		const apiData = {
			context,
			params: {
				email,
			},
		};

		if (cta?.event === BUTTON_INTERACTION.SYLLABUS_DOWNLOAD) {
			apiData.onSuccess = () => {
				setEmail("");
				setProgram(null);
				setFormMessage({
					status: "success",
					message: "DONE! The course syllabus has been sent to your email.",
				});
			};
			apiData.onError = () => {
				setFormMessage({
					status: "error",
					message: "We encountered an error. Please try again.",
				});
			};
		}

		const [referral, referral2] = GetReferrer();
		let eventData = {
			lead_type: cta?.event,
			page_section: pageSection,
			email,
			click_text: cta?.buttonText,
			course_slug: hasProgram ? program.course_slug : context?.slug,
			referral,
			referral2,
			products: JSON.stringify([{ product_id: context?.workshop_id }]),
			redirect_to: cta?.link?.link || context?.typeformLink,
		};
		setLoading(true);
		handleCTAClick(cta, eventData, apiData, null, { callbackOnError });
		!isB2U && eventData?.email && callB2CIdentifyEvent(eventData?.email);
	};

	const brandBarJSX = (brandBarData) => {
		let logoItems = [];
		brandBarData?.brandLogos?.forEach((item, i) => {
			if (item?.image?.image) {
				logoItems.push(
					<Image
						key={`logo-image-${i}`}
						className="hero-banner-logo"
						src={item?.image?.image}
						alt={item?.image?.altTag}
						srcBase64={item?.image?.base64}
						quality="auto"
						lazyload
						dynamic
						fetchFormat="auto"
						sizes={{
							default: {
								height: 20,
							},
						}}
						preload={true}
					/>,
				);
			}
		});

		return (
			<>
				{brandBarData?.hasSeparatorOnTop && <Separator />}
				<HorizontalList
					listItems={logoItems}
					maxItems={parseInt(brandBarData?.maxLogosInRow, 10)}
					labelConfig={{
						text: brandBarData?.labelText,
						color: hasDarkBackground
							? "var(--primary-neutral-white)"
							: "var(--secondary-gray-dark)",
					}}
					gap="20px"
					centered={!hasBackgroundImage && isCenter}
				/>
			</>
		);
	};

	const programFormJSX = (formData) => {
		const formComponent = formData?.component;
		if (
			formComponent === "hubspotStagedForm" ||
			formComponent === "hubspotStagedFormWithCourseSelector"
		) {
			return (
				<HubspotStagedForm
					formDetails={formData}
					context={context}
					pageSection={pageSection}
					hasCourseSelector={
						formComponent === "hubspotStagedFormWithCourseSelector"
					}
				/>
			);
		}
		if (formComponent === "FormWrapper") {
			return (
				<FormWrapper
					{...FormWrapperTransform(formData, context)}
					occupyFullWidth={true}
					pageSection={pageSection}
				/>
			);
		}
		return (
			<HeroFormWrapper
				className="hero-banner-wr-program-form"
				backgroundColor={
					formData?.hasBackground && formData?.backgroundColor?.color
				}
				hasProgramDropdown={formData?.hasProgramDropdown}
				darkBg={formData?.darkBackground?.value}
			>
				<Form
					className="form-with-background"
					darkBg={formData?.darkBackground?.value}
					onSubmit={(e) => {
						e.preventDefault();
						handleProgramFormSubmit(
							formData?.hasProgramDropdown,
							formData?.cta[0],
						);
					}}
				>
					{formData?.formHeading ? (
						<Text
							variant="headline_5"
							tag="p"
							color={
								!formData?.darkBackground?.value
									? COLORS.HEADING_DARK
									: COLORS.HEADING_LIGHT
							}
						>
							{formData?.formHeading}
						</Text>
					) : null}
					{formData?.formSubHeading ? (
						<Text
							variant="headline_6"
							color={
								!formData?.darkBackground?.value
									? COLORS.HEADING_DARK
									: COLORS.HEADING_LIGHT
							}
						>
							{formData?.formSubHeading}
						</Text>
					) : null}
					{formData?.hasProgramDropdown ? (
						<Select
							name="program"
							label={formData?.programDropdownLabel}
							placeholder={"Please select..."}
							required
							showErrors={showErrors}
							value={program}
							error={[error, setError]}
							onChange={(target, event) => {
								event.preventDefault();
								if (target?.target?.value) {
									setProgram(JSON.parse(target.target.value));
								} else {
									setProgram(null);
								}
							}}
						>
							{formData?.programDropdownItems?.map((item, index) => (
								<option
									value={JSON.stringify(item)}
									key={index}
									id={item.workshop_id}
								>
									{item.course_name}
								</option>
							))}
						</Select>
					) : null}
					<Input
						name="email"
						label={formData?.emailnputLabel}
						type="email"
						showErrors={showErrors}
						required
						value={email}
						error={[error, setError]}
						placeholder="Please enter email"
						onChange={(e) => setEmail(e.target.value)}
					/>
					<Button
						buttonConfiguration={isB2U && formData?.cta[0]}
						type="submit"
						buttonText={formData?.cta?.[0]?.buttonText}
						darkBg={formData?.cta?.[0]?.darkBg}
						disabled={loading}
					/>
					{formMessage?.status ? (
						<Text
							variant="paragraph_S_bold"
							className={`form-${formMessage?.status}-message`}
						>
							{formMessage?.message}
						</Text>
					) : null}
				</Form>
			</HeroFormWrapper>
		);
	};

	const imageSectionJSX = (
		<ImageSectionWrapper
			stretchImage={imageSection?.stretchImage}
			hasCard={imageSection?.card?.[0]}
		>
			{imageSection?.image?.[0]?.image?.image && (
				<Image
					className="hero-image right-hero-image"
					src={imageSection?.image[0]?.image?.image}
					alt={imageSection?.image[0]?.image?.altTag}
					srcBase64={imageSection?.image[0]?.image?.base64}
					quality="auto"
					lazyload
					fetchFormat="auto"
					sizes={{
						default: {
							height: 525,
						},
					}}
					preload={true}
				/>
			)}
			{/* Image Section  */}
			{imageSection?.card?.[0]?.title && (
				<div className="hero-image-card alumni-card">
					<Flow className="card-flow" gap="4px">
						<Text variant="paragraph_S_bold" id="hero-alumni-title">
							{imageSection?.card[0]?.title}
						</Text>
						<Text variant="paragraph_S" id="hero-alumni-subtitle">
							{imageSection?.card[0]?.subTitle}
						</Text>
						<Text variant="paragraph_S" tag="div" id="hero-alumni-description">
							{imageSection?.card[0]?.description}
						</Text>
					</Flow>
					<Image
						className="hero-image brand-logo"
						src={imageSection?.card[0]?.avatar[0]?.image?.image}
						alt={imageSection?.card[0]?.avatar[0]?.image?.altTag}
						srcBase64={imageSection?.card[0]?.avatar[0]?.image?.base64}
						quality="auto"
						lazyload
						fetchFormat="auto"
						sizes={{
							default: {
								height: 32,
							},
						}}
						preload={true}
					/>
				</div>
			)}
		</ImageSectionWrapper>
	);

	const videoSectionJSX = (
		<>
			{imageSection?.videoUrl ? (
				<VideoWrapper>
					<VideoPlayer
						onClick={() => {
							setOpenVideoModal(true);
						}}
						className="video-image"
						haveModal={false}
						videoImageUrl={imageSection?.videoImage?.image}
						mediaUrl={imageSection?.videoUrl}
					/>
					<Modal
						className="video-modal"
						show={openVideoModal}
						disableBackdropClick={true}
						onClose={() => setOpenVideoModal(false)}
						media
					>
						{openVideoModal && <VideoElement url={imageSection?.videoUrl} />}
					</Modal>
				</VideoWrapper>
			) : null}
		</>
	);

	const mainSectionJSX = (
		<>
			{icon || label?.length > 0 ? (
				<div className="flex-row-center">
					{icon ? <Icon iconName={icon?.iconName} size="56px" /> : null}
					{label.length > 0 ? (
						<div className="label-overflow">
							{label.map((item, i) => {
								if (item.text) {
									return (
										<Badge
											key={`icon-badge-${i}`}
											bgColor={item?.backgroundColor?.color}
											textColor={item?.fontColor?.color}
										>
											{item.text}
										</Badge>
									);
								}
							})}
						</div>
					) : null}
				</div>
			) : null}
			{heading && (
				<Text
					variant="headline_1"
					tag={h1Content === "heading" ? "h1" : "p"}
					id="hero-banner-heading"
					color={headingColor}
				>
					{heading}
				</Text>
			)}
			{subHeading && (
				<Text
					variant="headline_5"
					tag={h1Content === "subHeading" ? "h1" : "p"}
					id="hero-banner-subheading"
					color={textColor}
				>
					{subHeading}
				</Text>
			)}
			{text && (
				<Text
					id="hero-banner-text"
					className="rich-text-wrapper"
					color={textColor}
					tag="div"
					dangerouslySetInnerHTML={{ __html: text }}
				/>
			)}
			{multiStepForm && (
				// MULTISTEP FORM FOR DESKTOP
				<MultiStepForm
					multiStepForm={multiStepForm}
					headingColor={headingColor}
					textColor={textColor}
					hasDarkBackground={hasDarkBackground}
					hasContentAbove={multiStepHasContentAbove}
					hasContentBelow={multiStepHasContentBelow}
					context={context}
					pageSection={pageSection}
				/>
			)}

			{starRatingWithLogo && Object.keys(starRatingWithLogo).length > 0 && (
				<StarRatingWithLogo
					starRatingWithLogo={starRatingWithLogo}
					textRatingColor={textColor}
					hasDarkBackground={hasDarkBackground}
				></StarRatingWithLogo>
			)}

			{form && programFormJSX(form)}
			{brandBar ||
			listPointerItems ||
			alumniBar ||
			cta ||
			programInfoProps?.shortName ? (
				<Flow gap="2rem">
					{brandBar && !brandBar?.brandBarAtBottom
						? brandBarJSX(brandBar)
						: null}
					{listPointerItems ? (
						<VerticalList
							darkBg={hasDarkBackground}
							listItems={listPointerItems}
							iconConfig={{
								iconName: listPointers?.icon[0]?.iconName,
								size: listPointers?.icon[0]?.size,
								stroke: formatColor(listPointers?.icon[0]?.stroke),
							}}
							listBulletType="checkmarks"
							className="hero-banner-list"
						/>
					) : null}

					{brandBar && brandBar?.brandBarAtBottom
						? brandBarJSX(brandBar)
						: null}

					{alumniBar ? <AlumniBar {...alumniBar} centered={isCenter} /> : null}
					{cta ? (
						<Button
							buttonConfiguration={isB2U && cta}
							{...cta}
							buttonText={cta?.buttonText}
							className={`cta-button ${
								isUniversityHeroBanner ? "university" : ""
							}`}
							type={cta?.type}
							onClick={() => handleButtonClick(cta)}
							darkBg={cta?.darkBg}
							disabled={cta?.disabled}
						/>
					) : null}
					{programInfoProps?.shortName && (
						<ProgramInfo
							{...programInfoProps}
							hasHeroBannerDarkBg={hasDarkBackground}
							fetchApiUrl={fetchApiUrl}
						/>
					)}
				</Flow>
			) : null}
		</>
	);

	const rightSectionJSX = (
		<>
			<div className="rightsection-wrapper">
				{rightSection?.brandBar &&
					!rightSection?.brandBar?.brandBarAtBottom &&
					brandBarJSX(rightSection.brandBar)}
				{rightSection?.partnership && (
					<div className="partnership">
						<Text variant="label_M" color={headingColor}>
							{rightSection?.partnership?.text}
						</Text>
						<CloudinaryImage
							className="partnership-image"
							url={rightSection?.partnership?.logoUrl?.image}
							quality="auto"
							lazyload
							fetchFormat="auto"
							sizes={{
								default: {
									height: 24,
								},
							}}
							alt={rightSection?.partnership?.logoUrl?.altTag}
						/>
					</div>
				)}
				{rightSection?.text && (
					<Text
						color={textColor}
						className="rich-text-wrapper"
						tag="div"
						dangerouslySetInnerHTML={{ __html: rightSection?.text }}
					/>
				)}
				{rightSection?.brandBar?.brandBarAtBottom &&
					brandBarJSX(rightSection.brandBar)}
				{rightSection?.form && programFormJSX(rightSection?.form)}

				{rightSection?.cta && (
					<div className="rightsection-buttons">
						{rightSection?.cta.map((cta, i) => {
							return (
								<Button
									buttonConfiguration={isB2U && cta}
									key={`button-${i}`}
									buttonText={cta?.buttonText}
									type={cta?.type}
									onClick={() => handleButtonClick(cta)}
									darkBg={cta?.darkBg}
									disabled={cta?.disabled}
								/>
							);
						})}
					</div>
				)}
			</div>
		</>
	);

	let contentLeft =
		imageSection && imageSection.alignment == "left"
			? imageSectionJSX
			: mainSectionJSX;

	let contentRight = null;
	if (!hasBackgroundImage) {
		if (!imageSection) {
			contentRight = rightSection ? rightSectionJSX : null;
		} else if (imageSection && imageSection?.videoUrl) {
			contentRight = videoSectionJSX;
		} else if (imageSection && imageSection.alignment == "right") {
			contentRight = imageSectionJSX;
		} else {
			contentRight = mainSectionJSX;
		}
	} else if (rightSection?.form) {
		contentRight = rightSectionJSX;
	}

	return (
		<HeroBannerWRWrapper
			hasFooter={footer}
			className={`hero-banner-wr ${
				isUniversityHeroBanner ? "up-banner-wrapper" : ""
			}`}
			isCenter={isCenter}
			borderColor={bottomBorderColor}
			imagePos={imageSection?.alignment}
			hasDarkBackground={hasDarkBackground}
			hasBackgroundImage={hasBackgroundImage}
			stretchImage={imageSection?.stretchImage}
			hasImageCard={!!imageSection?.card?.[0]?.title}
			isUniversityHeroBanner={isUniversityHeroBanner}
			removeAdditionalTextSpace={removeAdditionalTextSpace}
			hideProgramInfoInMobile={hideProgramInfoInMobile}
		>
			{backgroundVideo?.length ? (
				<HeroBannerVideoPlayer
					autoPlay
					muted
					loop
					playsInline
					role="presentation"
					aria-hidden="true"
					darkenVideo={darkenVideo}
				>
					{backgroundVideo.map((video, id) => {
						return <source key={id} type={video?.type} src={video?.url} />;
					})}
				</HeroBannerVideoPlayer>
			) : null}

			<div
				className={`grid-1 ${
					isUniversityHeroBanner ? "up-hero-banner-wr" : ""
				} ${contentRight ? "has-right-section" : ""}`}
			>
				{contentLeft}
			</div>
			{contentRight && (
				<div
					className={`grid-2 ${
						imageSection && imageSection?.alignment == "left"
							? "image-left"
							: ""
					}`}
				>
					{contentRight}
				</div>
			)}
			{!isMobile ? <CommonLines variant={lineGraphicVariant} /> : <></>}

			{learningPathPopup && (
				<LearningPathPopup context={context} {...learningPathPopup} />
			)}

			{footer?.length ? (
				<FooterWrapper>
					<Separator />
					<ValueIconBarWrapper className="valueIconBar">
						{footer.map(({ icon, iconSize, text, id }) => {
							return (
								<div className="item-container" key={id}>
									<Icon
										iconName={icon}
										size={
											isMobileL_BP ? `${iconSize * 0.75}rem` : `${iconSize}rem`
										}
										stroke="var(--primary-green-600)"
									/>
									<Text variant={isMobileL_BP ? "paragraph_M" : "paragraph_L"}>
										{text}
									</Text>
								</div>
							);
						})}
					</ValueIconBarWrapper>
				</FooterWrapper>
			) : null}
		</HeroBannerWRWrapper>
	);
};

export default HeroBannerWR;
